import { Component, HostListener, Inject, Input } from "@angular/core"
import { CommonModule, DOCUMENT } from "@angular/common"
import { MenuComponent } from "@components/menu/menu.component"
import { LogoComponent } from "@components/logo/logo.component"
import { IMenu } from "@interfaces/menu.interface"
import { RouterLink } from "@angular/router"

@Component({
  selector: "app-header",
  standalone: true,
  imports: [CommonModule, MenuComponent, LogoComponent, RouterLink],
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss", "styles/navigation.scss"],
})
export class HeaderComponent {
  public isNavigationPanelActive: boolean | null = null
  public navigationWasOpened: boolean = false

  @Input() black: boolean = false;
  @Input() white: boolean = false;
  @Input() umniyDom: boolean = false;
  @Input() lightTextLogo: boolean = false;
  @Input() darkTextLogo: boolean = false;
  @Input() public email: string = ""
  @Input() public phone: string = ""
  @Input() public menu: IMenu[] = []
  @Input() public umniydom_menu: IMenu[] = []
  

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public toggleNavigation(): void {
    this.isNavigationPanelActive = !this.isNavigationPanelActive
    this.navigationWasOpened = true
    if (this.isNavigationPanelActive) {
      this.document.body.classList.add("navigation-active")
    } else {
      this.document.body.classList.remove("navigation-active")
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(e: any) {
    if (e.target.innerWidth > 1279) {
      this.navigationWasOpened = false
    }
  }

}
