<footer class="footer">
  <div class="container container--umniydom-mid">
    <div class="footer__wrap">
      <div class="footer__description" [innerHTML]="description"></div>

      <div class="footer__contacts">
        <address class="footer__address" [innerHTML]="address"></address>
        <div class="footer__links">
          <a class="footer__link" [href]="'tel:' + phone">{{ phone }}</a>
          <div class="footer__online">
            <a class="footer__link" [href]="'mailto:' + email">{{ email }}</a>
            <div class="footer__chat">
              <figure class="card__icon">
                <a [href]="telegramChat" target="_blank"><img *ngIf="telegram" [src]="assetsUrl + telegram" /></a>
              </figure>
              <figure class="card__icon">
                <a [href]="whatsapChat" target="_blank"><img *ngIf="whatsapp" [src]="assetsUrl + whatsapp" /></a>
              </figure>
            </div>
          </div>
        </div>
      </div>

      <div class="footer__links">
        <ul>
          <li *ngFor="let item of menu" class="footer__menu-item">
            <a [routerLink]="item.link">{{ item.text }}</a>
          </li>
        </ul>
        <div [innerHTML]="copyright"></div>
      </div>
    </div>
  </div>
</footer>
