<nav class="menu" [ngClass]="{ 'umniy-dom': umniyDom }">
  <ul class="menu__list">
    <ng-container *ngFor="let item of menuList; let i = index; let isLast = last">
      <li class="menu__item" *ngIf="item.anchor" [routerLink]="item.link" (click)="scrollToAnchor(item.anchor)">
        <button
          class="menu__link button"
          [ngClass]="{
            'button--primary-outline-wide': isLast && isHomePage,
            'menu__link-color-white': umniyDom,
            'menu__link-color-black': isHomePage
          }"
        >
          {{ item.text }}
        </button>
      </li>
      <ng-container *ngIf="isPenultimateItem(i)">
        <li class="menu__item" *ngIf="phone">
          <a class="navigation__link" [href]="'tel:' + phone">{{ phone }}</a>
        </li>
      </ng-container>
    </ng-container>
  </ul>
</nav>
