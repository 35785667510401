<header class="header" [class.header--black]="black">

  <div class="container container--wide">
    <a [routerLink]="'/'" class="header__logo">
      <app-logo [lightTextLogo]="lightTextLogo" [darkTextLogo]="darkTextLogo"></app-logo>
    </a>
    <div class="navigation__blackout" *ngIf="isNavigationPanelActive" (click)="toggleNavigation()"></div>
    <div class="navigation" [ngClass]="{ 'navigation--black': umniyDom }"
         [class.navigation--active]="isNavigationPanelActive"
         [class.navigation--animation]="navigationWasOpened">
      <div class="navigation__close" (click)="toggleNavigation()">
        <div class="navigation__handle" [class.navigation__handle--black]="umniyDom">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L17 17M17 1L1 17" stroke="#272829" stroke-width="2" />
          </svg>
        </div>
      </div>
      <div class="navigation__wrap" [ngClass]="{ 'navigation--black': umniyDom }">

        <app-menu (clickMenuItem)="toggleNavigation()" [phone]="phone" [umniyDom]="umniyDom" [menuList]="umniyDom ? umniydom_menu : menu"></app-menu>
      </div>
    </div>

    <button class="navigation-toggle button" [ngClass]="{ 'navigation--toggle-white': white }" (click)="toggleNavigation()" aria-label="menu">
      <svg width="24" height="12" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 1H24M0 6H24M0 11H24" stroke="#272829" stroke-width="2" />
      </svg>
    </button>
  </div>
</header>
