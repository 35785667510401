import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { IGlobal, initialGlobal } from '@interfaces/global.interface';
import { ISupportFormSection, initialSupportFormSection } from '@interfaces/support-form.interface';
import { Apollo } from 'apollo-angular';
import { LoadingService } from '@services/loading.service';
import { PlatformService } from '@services/platform.service';
import { GLOBAL_QUERY } from '@queries/global.query';
import { getGlobalUi } from '@functions/global.function';
import { getFormUi } from '@functions/form.function';
import { environments } from 'src/environments/environments';

@Component({
  selector: 'app-umniydom-layout',
  templateUrl: './umniydom-layout.component.html',
  styleUrls: ['./umniydom-layout.component.scss']
})
export class UmniydomLayoutComponent implements OnInit {
  public global: IGlobal = initialGlobal
  public form: ISupportFormSection = initialSupportFormSection
  public umniyDom: boolean = true

  private static calculateWindowHeight(): void {
    const vh: number = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }

  constructor(
    @Inject(DOCUMENT) private dom: Document,
    private apollo: Apollo,
    private loadingService: LoadingService,
    private platformService: PlatformService,
  ) {
    this.loadingService.set(true)
    this.preConnectLink()
  }

  ngOnInit(): void {
    if (this.platformService.isBrowser) {
      UmniydomLayoutComponent.calculateWindowHeight()
    }
    this.apollo
      .watchQuery({
        query: GLOBAL_QUERY,
        variables: { status: "published" },
      })
      .valueChanges.subscribe((data: any) => {
        this.global = getGlobalUi(data.data.global)
        this.form = getFormUi(data.data.form)
      })
  }

  private preConnectLink() {
    const head = this.dom.getElementsByTagName("head")[0]
    let element: HTMLLinkElement | null = this.dom.querySelector(`link[rel='preconnect']`)
    if (!element) {
      element = this.dom.createElement("link") as HTMLLinkElement
      head.appendChild(element)
    }
    element.setAttribute("rel", "preconnect")
    element.setAttribute("href", environments.directusApiUrl)
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    UmniydomLayoutComponent.calculateWindowHeight()
  }
}
