import { Component, EventEmitter, Input, Output } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms"
import { emailValidator } from "../../shared/validators/email.validators"
import { initialSupportFormLabels, ISupportForm, ISupportFormLabels } from "@interfaces/support-form.interface"
import { HttpService } from "@services/http.service"


@Component({
  selector: "app-support-form",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  templateUrl: "./support-form.component.html",
  styleUrls: ["./support-form.component.scss"],
})
export class SupportFormComponent {
  @Output() clickSubmit = new EventEmitter<string>()
  @Input() formModal: boolean = false
  @Input() umniyDomModal: boolean = false
  @Input() umniyDom: boolean = false
  @Input() consult: boolean = false
  @Input() fields: ISupportFormLabels = initialSupportFormLabels
  @Input() text: string = ""
  @Input() button: string = ""
  @Input() info: string = ""
  @Input() choose: string = ""
  @Input() textAttach: string = ""
  public file: string = ""
  public formGroup = this.fb.group({
    name: ["", Validators.required],
    email: ["", [Validators.required, emailValidator]],
    phone: [null, [Validators.required, Validators.pattern("[- +()0-9]+")]],
    message: [""],
    messageAttach: [""],
  })

  constructor(private readonly fb: FormBuilder, private httpService: HttpService) {}

  public onSubmit(): void {
    Object.values(this.formGroup.controls).forEach((val) => {
      val.markAsTouched()
      val.markAsDirty()
    })
    if (this.formGroup.invalid) return
    const formValue: any = this.formGroup.getRawValue()
    const formData: ISupportForm = {
      name: formValue.name,
      email: formValue.email || "",
      phone: formValue.phone,
      message: formValue.message || "",
      messageAttach: formValue.messageAttach || "",
    }

    this.httpService.postSupportForm(formData).subscribe({
      next: () => {
        this.clickSubmit.emit("")
      },
      error: () => {
        this.clickSubmit.emit("Error!")
      },
    })
console.log(formData)
  }

  public onFileSelected(event: any) {
    const file: File = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const base64String = reader.result as string
      this.file = base64String
    }
  }
}
