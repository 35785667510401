import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from '@components/modal/modal.component';
import { SupportFormComponent } from '@components/support-form/support-form.component';
import { FormMessageComponent } from '@components/form-message/form-message.component';
import { RouterLink } from '@angular/router';
import { IMenu } from '@interfaces/menu.interface';
import {
  initialSupportFormSection,
  ISupportFormSection,
} from '@interfaces/support-form.interface';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent,
    SupportFormComponent,
    FormMessageComponent,
    RouterLink,
  ],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() public copyright: string = '';
  @Input() public phone: string = '';
  @Input() public address: string = '';
  @Input() public email: string = '';
  @Input() public title: string = '';
  @Input() public description: string = '';
  @Input() public button: string = '';
  @Input() public menu: IMenu[] = [];
  @Input() public successForm: ISupportFormSection = initialSupportFormSection;

  public openModalSupportForm: boolean = false;
  public openModalMessage: boolean = false;
  public successTitleForm: string = '';

  public onOpenSupportForm(): void {
    this.openModalSupportForm = true;
  }

  public onCloseSupportForm(): void {
    this.openModalSupportForm = false;
  }

  public onCloseMessageForm(): void {
    this.openModalMessage = false;
  }

  public onSubmitForm(message: string): void {
    this.onCloseSupportForm();
    this.openModalMessage = true;
    this.successTitleForm = message ? message : this.successForm.textSuccess;
  }
}
