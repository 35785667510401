<section class="section"
         [class.section--columns]="columnsView"
         [class.section--black]="black"
         [class.section--grey]="grey"
         [class.section--divider]="divider">
  <div class="section__inner" [class.container]="!containerWide">
    <div class="section__header" *ngIf="title || text">
      <h2 class="section__title h3" [innerHTML]="title" [class.section__title--center]="centerTitle"></h2>
      <div class="section__text" *ngIf="text" [innerHTML]="text"></div>
    </div>
    <div class="section__body">
      <ng-content></ng-content>
    </div>
  </div>
</section>
