import { NgModule } from "@angular/core"
import { BrowserModule, provideClientHydration } from "@angular/platform-browser"
import { AngularSvgIconModule } from "angular-svg-icon"
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"

import { AppRoutingModule } from "./app-routing.module"
import { AppComponent } from "./app.component"
import { HeaderComponent } from "@components/header/header.component"
import { FooterComponent } from "@components/footer/footer.component"
import { SectionComponent } from "@components/section/section.component"
import { GraphQLModule } from "./graphql.module"
import { LoadingSpinnerComponent } from "@components/loading-spinner/loading-spinner.component"
import { BrowserStateInterceptor } from "@services/browser-state.interceptor"
import { HomeLayoutComponent } from "../app/_layout/home-layout/home-layout.component"
import { UmniydomLayoutComponent } from "../app/_layout/umniydom-layout/umniydom-layout.component"
import { FooterUmniydomComponent } from "../app/components/footer-umniydom/footer-umniydom.component"

@NgModule({
  declarations: [AppComponent, UmniydomLayoutComponent, HomeLayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    HeaderComponent,
    FooterComponent,
    SectionComponent,
    GraphQLModule,
    LoadingSpinnerComponent,
    FooterUmniydomComponent
  ],
  providers: [
    provideClientHydration(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
