import { Component, Input, OnInit } from "@angular/core"
import { CommonModule } from "@angular/common"
import { Router } from "@angular/router"

@Component({
  selector: "app-logo",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./logo.component.html",
  styleUrls: ["./logo.component.scss"],
})
export class LogoComponent {
  @Input() lightColor: boolean = false
  @Input() darkTextLogo: boolean = false
  @Input() lightTextLogo: boolean = false

}
