import { Component, EventEmitter, HostListener, Inject, Input, Output } from "@angular/core"
import { CommonModule, DOCUMENT } from "@angular/common"
import { IMenu } from "@interfaces/menu.interface"
import { Router } from "@angular/router"
import { RouterModule } from "@angular/router"

@Component({
  selector: "app-menu",
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent {
  @Output() clickMenuItem = new EventEmitter<void>()

  @Input() public menuList: IMenu[] | null = []
  @Input() public phone: string = ""
  @Input() umniyDom: boolean = false

  public headerScrolled: boolean = false

  private currentUrl: string = "/"
  public isHomePage = true

  constructor(@Inject(DOCUMENT) private document: Document, private router: Router) {
    this.router.events.subscribe(() => {
      this.currentUrl = this.router.url.toString()
    })
  }

  @HostListener("body:scroll", ["$event"])
  onScroll() {
    this.headerScrolled = this.document.body.scrollTop > 150
  }

  public scrollToAnchor(anchor: string): void {
    if (anchor) {
      const isHomePage = this.currentUrl === "/" || this.currentUrl.startsWith("/#") || anchor === "contacts"

      const isUmniyDomPage = "/umniy-dom" || this.currentUrl.startsWith("/umniy-dom#") || anchor === "contacts"

      if (!isHomePage && !isUmniyDomPage) {
        this.router.navigate([""], { fragment: anchor })
      } else {
        const componentScroll = this.document.getElementById(anchor)!
        if (componentScroll) {
          componentScroll.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          })
        }
      }
    }

    this.clickMenuItem.emit()
  }

  // public scrollToAnchor(anchor: string): void {
  //   if (anchor) {
  //    const componentScroll = this.document.getElementById(anchor)!;
  //       if (componentScroll) {
  //         componentScroll.scrollIntoView({
  //           behavior: 'smooth',
  //           block: 'start',
  //           inline: 'nearest',
  //         });
  //       }
  //   }

  //   this.clickMenuItem.emit();
  // }

  isPenultimateItem(index: number): boolean {
    if (this.menuList && this.menuList.length >= 2) {
      if (!this.umniyDom) {
        this.isHomePage = true
        return index === this.menuList.length - 2
      } else if (this.umniyDom) {
        this.isHomePage = false
        return index === this.menuList.length - 1
      }
    }
    return false
  }
}
