<form
  class="form"
  [ngClass]="{ 'form--modal': formModal }"
  [formGroup]="formGroup"
  (ngSubmit)="onSubmit()"
  #ngForm="ngForm"
>
  <div class="form__body" [ngClass]="{ 'form--body-umniy-dom': umniyDom, 'form--body-umniy-dom-consult': consult }">
    <label class="form__group">
      <input
        type="text"
        class="field"
        [ngClass]="{ 'field--field-umniy-dom': umniyDom }"
        [placeholder]="fields.name"
        formControlName="name"
      />
      <span *ngIf="!umniyDom" class="field-border"></span>
      <span class="field-error" *ngIf="!formGroup.controls['name'].value && ngForm.submitted"> Обязательное поле </span>
    </label>
    <label class="form__group">
      <input
        type="email"
        class="field"
        [ngClass]="{ 'field--field-umniy-dom': umniyDom }"
        [placeholder]="fields.email"
        formControlName="email"
      />
      <span *ngIf="!umniyDom" class="field-border"></span>
      <span class="field-error" *ngIf="!formGroup.controls['email'].value && ngForm.submitted">
        Обязательное поле
      </span>
      <span
        class="field-error"
        *ngIf="formGroup.controls['email'].value && formGroup.controls['email'].invalid && ngForm.submitted"
      >
        Email указан некорректно
      </span>
    </label>
    <label class="form__group">
      <input
        type="tel"
        class="field"
        [ngClass]="{ 'field--field-umniy-dom': umniyDom }"
        [placeholder]="fields.phone"
        formControlName="phone"
      />
      <span *ngIf="!umniyDom" class="field-border"></span>
      <span class="field-error" *ngIf="!formGroup.controls['phone'].value && ngForm.submitted">
        Обязательное поле
      </span>
      <span
        class="field-error"
        *ngIf="formGroup.controls['phone'].value && formGroup.controls['phone'].invalid && ngForm.submitted"
      >
        Телефон указан некорректно
      </span>
    </label>
    <label class="form__group" *ngIf="formModal">
      <textarea
        class="field field--textarea"
        [ngClass]="{ 'field--field-umniy-dom': umniyDom }"
        [placeholder]="info ? info : fields.message"
        formControlName="message"
      ></textarea>
      <span *ngIf="!umniyDom" class="field-border"></span>
    </label>
    <label *ngIf="!umniyDomModal" class="form__group">
      <textarea
        class="field field--textarea"
        [ngClass]="{ 'field--field-umniy-dom': umniyDom }"
        [placeholder]="textAttach"
        formControlName="messageAttach"
      ></textarea>
      <span *ngIf="!umniyDom" class="field-border"></span>
    </label>
  </div>
  <div class="form__footer">
    <div class="form__group form__group--submit">
      <button
        type="submit"
        class="button button--primary-outline-dark"
        [ngClass]="{
          'button--primary-on-dark': !formModal,
          'button--primary-on-light': formModal,
          'button--primary-outline-light': umniyDomModal,
          'button--primary-outline-dark': umniyDom
        }"
      >
        {{ button }}
      </button>
    </div>
    <div *ngIf="!umniyDom" class="form__text" [innerHTML]="text"></div>
  </div>
</form>
