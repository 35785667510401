<footer class="footer">
  <div class="container container--full">
    <div class="footer__row">
      <div class="footer__column">
        <div class="footer__banner">
          <h2 class="footer__title" [innerHTML]="title"></h2>
          <button class="button button--light" (click)="onOpenSupportForm()">{{ button }}</button>
        </div>
      </div>
      <div class="footer__column">
        <div class="footer__contacts">
          <address class="footer__address" [innerHTML]="address"></address>
          <div class="footer__links">
            <a class="footer__link" [href]="'tel:' + phone">{{ phone }}</a>
            <a class="footer__link" [href]="'mailto:' + email">{{ email }}</a>
          </div>
        </div>
      </div>
      <div class="footer__column">
        <div class="footer__contacts">
          <div>
            <div [innerHTML]="description"></div>
          </div>
          <div class="footer__links">
            <ul>
              <li *ngFor="let item of menu" class="footer__menu-item">
                <a [routerLink]="item.link">{{ item.text }}</a>
              </li>
            </ul>
            <div [innerHTML]="copyright"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

<app-modal
           *ngIf="openModalSupportForm"
           [title]="successForm.title"
           [activeModal]="openModalSupportForm"
           (clickClose)="onCloseSupportForm()">
  <app-support-form
                    [fields]="successForm.fields"
                    [text]="successForm.text"
                    [button]="successForm.button"
                    (clickSubmit)="onSubmitForm($event)"
                    [formModal]="true"></app-support-form>
</app-modal>

<app-modal
           *ngIf="openModalMessage"
           [activeModal]="openModalMessage"
           (clickClose)="onCloseMessageForm()"
           [compactView]="true"
           [hideCloseButton]="true">
  <app-form-message
                    [message]="successTitleForm"
                    [button]="successForm.buttonSuccess"
                    (clickClose)="onCloseMessageForm()"></app-form-message>
</app-modal>
