import { gql } from 'apollo-angular';

export const FORM_FIELDS = gql`
  fragment formFields on form {
    title
    field_name
    field_email
    field_phone
    field_message
    field_info
    button
    text
    text_attach
    button_choose
    button_success
    text_success
  }
`;