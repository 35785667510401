import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  public loading$: Observable<boolean>;
  private isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor() {
    this.loading$ = this.isLoading$.asObservable();
  }

  set(loading: boolean) {
    this.isLoading$.next(loading);
  }
}
