import { IGlobal, IGlobalApi } from '@interfaces/global.interface';

export const getGlobalUi = (globalApi: IGlobalApi): IGlobal => {
  return {
    email: globalApi.email,
    phone: globalApi.phone,
    menu: globalApi.menu,
    umniydom_menu: globalApi.umniydom_menu,
    copyright: globalApi.copyright,
    address: globalApi.address,
    footer: {
      title: globalApi.footer_title,
      description: globalApi.description,
      anchor: globalApi.footer_anchor,
      button: globalApi.footer_button_text,
      telegram_image: globalApi.footer_telegram_image.id,
      whatsapp_image: globalApi.footer_whatsapp_image.id,
      menu: globalApi.footer_menu,
    },
  };
};
