//fields
export interface ISupportForm {
  name: string;
  email: string;
  phone: string;
  message: string;
  messageAttach: string;
}

export interface ISupportFormLabels {
  name: string;
  email: string;
  phone: string;
  message: string;
  info: string;
}

//labels
export const initialSupportFormLabels: ISupportFormLabels = {
  name: '',
  email: '',
  phone: '',
  message: '',
  info: '',
};

//modals
export interface ISupportFormSection {
  title: string;
  fields: ISupportFormLabels;
  button: string;
  text: string;
  textAttach: string;
  buttonChoose: string;
  buttonSuccess: string;
  textSuccess: string;
}

export interface ISupportFormSectionApi {
  title: string;
  field_name: string;
  field_phone: string;
  field_email: string;
  field_message: string;
  field_info: string;
  button: string;
  text: string;
  text_attach: string;
  button_choose: string;
  button_success: string;
  text_success: string;
}

export const initialSupportFormSection: ISupportFormSection = {
  title: '',
  fields: initialSupportFormLabels,
  button: '',
  text: '',
  textAttach: '',
  buttonChoose: '',
  textSuccess: '',
  buttonSuccess: '',
};
