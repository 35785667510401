import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnInit,
  Renderer2,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [
    trigger('animateModal', [
      transition('void => *', [
        style({ opacity: 0, transform: 'translateY(10px)' }),
        animate('0.2s', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition('* => void', [
        animate('0.1s', style({ opacity: 0, transform: 'translateY(10px)' })),
      ]),
    ]),
    trigger('animateBlackout', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('0.15s', style({ opacity: 1 })),
      ]),
      transition('* => void', [animate('0.1s', style({ opacity: 0 }))]),
    ]),
  ],
})
export class ModalComponent implements OnInit, OnDestroy {
  @Output() clickClose = new EventEmitter<void>();
  @Input() activeModal: boolean = false;
  @Input() compactView: boolean = false;
  @Input() hideCloseButton: boolean = false;
  @Input() title: string = '';
  public showModal = false;
  private readonly classBody: string = 'overflow-hidden';

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.showModal = this.activeModal;
    this.renderer.addClass(document.body, this.classBody);
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, this.classBody);
  }

  public onClose(): void {
    this.showModal = false;
    setTimeout(() => {
      this.clickClose.emit();
    }, 200);
  }
}
