import { IMenu } from '@interfaces/menu.interface';

export interface IGlobalApi {
  menu: IMenu[];
  umniydom_menu: IMenu[];
  email: string;
  phone: string;
  address: string;
  copyright: string;
  description: string;
  footer_title: string;
  footer_anchor: string;
  footer_button_text: string;
  footer_telegram_image: {
    id: string;
  }
  footer_whatsapp_image: {
    id: string;
  }
  footer_menu: IMenu[];
}

export interface IGlobal {
  menu: IMenu[];
  umniydom_menu: IMenu[];
  email: string;
  phone: string;
  address: string;
  copyright: string;
  footer: {
    title: string;
    description: string;
    anchor: string;
    button: string;
    telegram_image: string;
    whatsapp_image: string;
    menu: IMenu[];
  };
}

export const initialGlobal: IGlobal = {
  menu: [],
  umniydom_menu: [],
  email: '',
  phone: '',
  address: '',
  copyright: '',

  footer: {
    description: '',
    title: '',
    anchor: '',
    button: '',
    telegram_image: '',
    whatsapp_image: '',
    menu: [],
  },
};
