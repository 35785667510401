import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingService } from '@services/loading.service';
import { LogoComponent } from '@components/logo/logo.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { PlatformService } from '@services/platform.service';

@Component({
  selector: 'app-loading-spinner',
  standalone: true,
  imports: [CommonModule, LogoComponent],
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  animations: [
    trigger('animateLoader', [
      transition('* => void', [animate('0.2s', style({ opacity: 0 }))]),
    ]),
  ],
  host: { ngSkipHydration: 'true' },
})
export class LoadingSpinnerComponent {
  public isLoading = true;

  constructor(
    private loadingService: LoadingService,
    private platformService: PlatformService
  ) {
    this.loadingService.loading$.subscribe((value) => {
      this.isLoading =
        !this.platformService.isBrowser ||
        (this.platformService.isBrowser && value);
    });
  }
}
