import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-section',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent {
  @Input() title: string = '';
  @Input() text: string = '';
  @Input() containerWide: boolean = false;
  @Input() columnsView: boolean = false;
  @Input() black: boolean = false;
  @Input() grey: boolean = false;
  @Input() centerTitle: boolean = false;
  @Input() divider: boolean = false;
}
