import { Injectable } from '@angular/core';
import { ISupportForm } from '@interfaces/support-form.interface';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, EMPTY } from 'rxjs';
import { environments } from '../../environments/environments';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) { }
  get assetsUrl(): string {
    return `assets/`;
  }

  get assetsDirectusUrl(): string {
    return `${environments.directusApiUrl}/assets/`;
  }

  // 'https://webapi.procesio.app/api/webhooks/launch/4d564f4b-15c5-4007-add8-12796fea8ca3' стартый url

  

  postSupportForm(formData: ISupportForm) {
    return this.http
      .post(
        'https://webapi.procesio.app/api/webhooks/launch/8f50c38b-deea-4025-9e51-fde2e60b583d',
        formData
      )
      .pipe(
        catchError((e: HttpErrorResponse) => {
          switch (e.status) {
            default:
              console.error(`Status: ${e.status}; Message: ${e.message}`);
              console.log('success')
              break;
          }
          return EMPTY;
        })
      );
  }

  downloadFile({ url, filename }: { url: string, filename: string }) {
    this.http.get(url, { responseType: 'blob' as 'json' }).subscribe(
      (response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        if (filename)
          downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }
    )
  }
}
