<div class="page">
    <app-loading-spinner></app-loading-spinner>
    <app-header
                [email]="global.email"
                [phone]="global.phone"
                [menu]="global.menu"
                [darkTextLogo]="true"></app-header>
    <router-outlet></router-outlet>
    <app-footer
                [id]="global.footer.anchor"
                [title]="global.footer.title"
                [button]="global.footer.button"
                [address]="global.address"
                [email]="global.email"
                [description]="global.footer.description"
                [phone]="global.phone"
                [copyright]="global.copyright"
                [menu]="global.footer.menu"
                [successForm]="form"></app-footer>
  </div>