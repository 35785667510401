import { FormControl } from '@angular/forms';

export function emailValidator(control: FormControl) {
  const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const russianRegexp = new RegExp(/[а-я,А-Я]/, 'gi');
  const { value } = control;

  return typeof value !== 'string' ||
                !value.length ||
                (value.length &&
                emailRegExp.test(control.value) && !russianRegexp.test(control.value))
    ? null
    : {
      emailValidator: {
        valid: false,
      },
    };
}
