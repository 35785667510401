import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { HomeLayoutComponent } from "./_layout/home-layout/home-layout.component"
import { UmniydomLayoutComponent } from "./_layout/umniydom-layout/umniydom-layout.component"

const routes: Routes = [
  //Home routes
  {
    path: "",
    component: HomeLayoutComponent,
    children: [
      {
        path: "",
        loadComponent: () => import("./pages/home/home.component").then((m) => m.HomeComponent),
      },
      {
        path: "cookie-policy",
        loadComponent: () => import("./pages/text-page/text-page.component").then((c) => c.TextPageComponent),
      },
    ],
  },
  // Umniydom routes goes here here
  {
    path: "",
    component: UmniydomLayoutComponent,
    children: [
      {
        path: "umniy-dom",
        loadComponent: () => import("./pages/umniy-dom/umniy-dom.component").then((m) => m.UmniyDomComponent),
      },
    ],
  },
  {
    path: "**",
    loadComponent: () => import("./pages/not-found/not-found.component").then((c) => c.NotFoundComponent),
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      anchorScrolling: "enabled",
      scrollOffset: [0, 0],
      scrollPositionRestoration: "enabled",
      initialNavigation: "enabledBlocking",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
