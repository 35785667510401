import {
  ISupportFormSection,
  ISupportFormSectionApi,
} from '@interfaces/support-form.interface';

export const getFormUi = (
  formApi: ISupportFormSectionApi
): ISupportFormSection => {
  return {
    title: formApi.title,
    fields: {
      name: formApi.field_name,
      email: formApi.field_email,
      phone: formApi.field_phone,
      message: formApi.field_message,
      info: formApi.field_info,
    },
    button: formApi.button,
    text: formApi.text,
    textAttach: formApi.text_attach,
    buttonChoose: formApi.button_choose,
    buttonSuccess: formApi.button_success,
    textSuccess: formApi.text_success,
  };
};
