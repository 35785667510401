import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IMenu } from '@interfaces/menu.interface';
import { RouterLink } from '@angular/router';
import { HttpService } from '@services/http.service';
import { telegram, whatsapp } from 'src/app/shared/variables/variables';

@Component({
  selector: 'app-footer-umniydom',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './footer-umniydom.component.html',
  styleUrls: ['./footer-umniydom.component.scss']
})
export class FooterUmniydomComponent {
  @Input() public copyright: string = '';
  @Input() public phone: string = '';
  @Input() public address: string = '';
  @Input() public email: string = '';
  @Input() public description: string = '';
  @Input() public telegram: string = '';
  @Input() public whatsapp: string = '';
  @Input() public menu: IMenu[] = [];
  
  public assetsUrl: string = this.httpService.assetsDirectusUrl
  public telegramChat: string = telegram
  public whatsapChat: string = whatsapp

  constructor(private httpService: HttpService) {}

}
