import { gql } from 'apollo-angular';
import { FORM_FIELDS } from '@queries/fragments/form.fragment';

export const GLOBAL_QUERY = gql`
  query GetGlobal($status: String!) {
    global {
      menu(filter: { status: { _eq: $status } }, sort: ["sort"]) {
        text
        anchor
        link
      }
      umniydom_menu(filter: { status: { _eq: $status } }, sort: ["sort"]) {
        text
        anchor
        link
      }
      email
      phone
      address
      description
      copyright
      footer_title
      footer_button_text
      footer_anchor
      footer_telegram_image {
        id
      }
      footer_whatsapp_image {
        id
      }
      footer_menu(filter: { status: { _eq: $status } }, sort: ["sort"]) {
        text
        anchor
        link
      }
    }
    form {
      ...formFields
    }
  }
  ${FORM_FIELDS}
`;
