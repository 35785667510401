<div class="page" >
  <!-- <app-loading-spinner></app-loading-spinner> -->
  <app-header
    [email]="global.email"
    [phone]="global.phone"
    [umniydom_menu]="global.umniydom_menu"
    [black]="true"
    [lightTextLogo]="true"
    [umniyDom]="umniyDom"
    [white]="true"
  ></app-header>
  <router-outlet></router-outlet>
  <app-footer-umniydom
    [id]="global.footer.anchor"
    [address]="global.address"
    [email]="global.email"
    [description]="global.footer.description"
    [phone]="global.phone"
    [copyright]="global.copyright"
    [telegram]="global.footer.telegram_image"
    [whatsapp]="global.footer.whatsapp_image"
    [menu]="global.footer.menu"
  ></app-footer-umniydom>
</div>