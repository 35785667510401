<div class="modal" *ngIf="showModal" @animateModal [ngClass]="{ 'modal--compact': compactView }">
  <div class="modal__container">
    <header class="modal__header" *ngIf="title && !hideCloseButton">
      <button class="modal__close button" (click)="onClose()" *ngIf="!hideCloseButton">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none"><path stroke-linecap="square" stroke-linejoin="round" stroke-width="2" d="M36 12 12 36M12 12l24 24"/></svg>
      </button>
      <h2 class="modal__title h3" *ngIf="title">{{ title }}</h2>
    </header>
    <section class="modal__content">
      <ng-content></ng-content>
    </section>
  </div>
</div>
<div class="modal-blackout" *ngIf="showModal" @animateBlackout (click)="onClose()"></div>
